import {Injectable} from '@angular/core';
import {CurdService} from './curd.service';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './toast.service';
import {saveAs} from 'file-saver';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropboxService {

  constructor(
    private curd: CurdService,
    private http: HttpClient,
    private toast: ToastService,
  ) {

  }

  mergeDocuments(url) {
    const header = {
      'Content-Type': 'application/json',
      'x-api-key': environment.pdf_co_key
    };

    return new Promise((resolve, reject) => {
      this.http.post('https://api.pdf.co/v1/pdf/merge2', {url: url, async: false}, {headers: header}).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });

  }

  upload(document, job_no) {
    return new Promise(resolve => {
      const access_token = localStorage.getItem('dropbox');
      let folder = `/${job_no}/${document.document}/${document.current_file}/${document.attachment.name}`;
      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/octet-stream',
        'Dropbox-API-Arg': JSON.stringify({
          path: folder,
          mode: 'add',
          autorename: true,
          mute: false
        })
      };
      this.http.post('https://content.dropboxapi.com/2/files/upload', document.attachment, {headers: headers})
        .pipe(this.toast.dropbox(document.document))
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getFile(file_path, file_name) {
    const access_token = localStorage.getItem('dropbox');
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/octet-stream',
      'Dropbox-API-Arg': JSON.stringify({
        path: file_path,
      })
    };
    // @ts-ignore
    this.http.post<any>('https://content.dropboxapi.com/2/files/download', null, {
      headers: headers,
      // @ts-ignore
      'responseType': 'blob'
    }).subscribe(file => {
      // @ts-ignore
      const blob = new Blob([file], {type: 'application/octet-stream'});
      saveAs(blob, file_name);

    });
  }

  getAccessToken() {
    this.curd.index('settings').subscribe(res => {
      localStorage.setItem('dropbox', res[0]['dropbox_token']);
    });
  }

  async createOrGetSharedLink(file_path) {

    const access_token = await localStorage.getItem('dropbox');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    };
    return new Promise((resolve,) => {
      this.http.post('https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings', {
        'path': file_path,
        'settings': {
          'audience': 'public',
          'access': 'viewer',
          'requested_visibility': 'public',
          'allow_download': true
        }
      }, {headers: headers})
        .subscribe(
          // tslint:disable-next-line: no-shadowed-variable
          data => {

            let replaced_url = data['url'].replace('dl=0', 'dl=1');

            resolve({
              url: replaced_url,
              name: data['name']
            });
          },
          err => {
            if (err.status = 409) {
              this.http.post('https://api.dropboxapi.com/2/sharing/list_shared_links', {path: file_path}, {headers: headers})
                .subscribe(
                  // tslint:disable-next-line: no-shadowed-variable
                  data => {
                    if (data['links'].length > 0) {
                      // @ts-ignore
                      let replaced_url = data.links[0].url.replace('dl=0', 'dl=1');

                      resolve({
                        // @ts-ignore
                        url: replaced_url,
                        //@ts-ignore
                        name: data.links[0].name

                      });
                    }
                  },
                );
            } else {
              // console.log(err);
            }
          }
        );
    });
  }
}
